import ElementPlus from 'element-plus'
import {UploadFilled} from '@element-plus/icons-vue'

// const compoents = [ElementPlus, UploadFilled]

const improtElement = (app)=>{
  app.use(ElementPlus)
  app.component('UploadFilled', UploadFilled)
}

export default improtElement