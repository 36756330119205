import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
// @ is an alias to /src
import { ref, reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import OSS from 'ali-oss';
import { nanoid } from 'nanoid';
import axios from '../plugins/api';
import { UploadFilled } from '@element-plus/icons-vue';
// import {ElMessage } from 'element-plus'
// import Base64 from '../untils/Base64.js'

import { Base64 } from 'js-base64';
export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      uploading: false,
      uploadPercentage: 0,
      errorMsg: '',
      success: false,
      orderId: null
    };
  },
  setup() {
    const fileInput_ref = ref();
    const upfile_ref = ref(false);
    const error_ref = ref(false);
    const loading = ref(false);
    const route = useRoute();
    const state = reactive({
      loadData: {},
      dialogTitle: {
        en: 'File is being uploaded, please do not close the page',
        zh: '文件上传中，请勿关闭页面！'
      }
    });
    const orderId = Base64.decode(route.query.id);
    const getVerify = async () => {
      // const {data:res} = await axios.get(`/technicalFile/technicalFileLinkCheck?order_id=${13861}`)
      const {
        data: res
      } = await axios.get(`/technicalFile/technicalFileLinkCheck?order_id=${orderId}`);
      console.log(res);
      if (res.code == 200) {
        state.loadData = res.data;
        if (res.data.type == 1) {
          // type =1 验证通过  上传文件
          upfile_ref.value = true;
        } else {
          // type=0
          error_ref.value = true;
        }
      } else {
        ElMessage.error(res.msg);
      }
    };
    if (route.query.id) {
      getVerify();
    }

    // 按钮上传
    const upFile = () => {
      fileInput_ref.value.dispatchEvent(new MouseEvent("click"));
    };
    return {
      fileInput_ref,
      ...toRefs(state),
      upfile_ref,
      error_ref,
      loading,
      upFile,
      getVerify,
      orderId
    };
  },
  methods: {
    async getFile(e) {
      // loading.value = true
      this.uploading = true;
      const file = e.raw;
      // console.log(nanoid())
      const oss = new OSS({
        // endpoint: 'oss-cn-hangzhou.aliyuncs.com',
        region: "oss-cn-hangzhou",
        accessKeyId: "LTAI5tFJUz3pg3zRa2fXWsnw",
        accessKeySecret: "lHT4CNWEvst6AtlddeBfpnRbEnH3og",
        bucket: "order-hwgwb-com"
      });
      // 截取文件 后缀
      let nameType = file.name.substring(file.name.lastIndexOf('.'));
      const fileName = 'order-files/technical_documents/' + nanoid() + nameType;
      console.log('fileName', fileName);
      let res = null;
      let URL = null;
      try {
        res = await oss.multipartUpload(`${fileName}`,
        //如果需要传入到指定的文件夹下，需要在文件名前加上文件夹名称，如： `xxxxx/${file.name}`
        file, {
          progress: (p, cpt, res) => {
            // 获取分片上传进度、断点和返回值。
            this.uploadPercentage = Math.trunc(p * 100);
          },
          parallel: 4,
          //并发上传的分片数量
          partSize: 1024 * 1024,
          //分片大小, 1 Mb
          // headers: { //上传请求头设置
          //   'Content-Disposition': 'inline',
          //   'Content-Type': nameType
          // },
          // meta: {year: 2020, people: "test"},// 自定义元数据，通过HeadObject接口可以获取Object的元数据。
          mime: nameType //上传文件类型
        });
        // console.log(res, 'result= 切片上传完毕=')
        URL = res.res.requestUrls[0];
        // if (file.size > 1024 * 1024 * 100) {

        // } else {
        //   console.log('put')
        //   res = await oss.put(fileName, file)
        //   console.log('put上传的', res)
        //   URL = res.url
        // }

        // 新增技术文件
        let fileUrl = 'http://order-hwgwb-com.oss-cn-hangzhou.aliyuncs.com/' + fileName;
        const result = await axios.post('/technicalFile/addTechnicalFile', {
          order_id: this.orderId,
          file_path: fileUrl
        });
        if (result.data.code == 200) {
          // 提交成功
          // loading.value = true
          // state.dialogTitle = {
          //   en: 'File upload succeeded',
          //   zh: '文件上传成功'
          // }
          this.success = true;
        } else {
          this.errorMsg = result.data.msg;
        }
      } catch (err) {
        this.errorMsg = '遇到错误，请把此信息截图给客服：' + err;
      }
    }
  }
};