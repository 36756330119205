import axios from 'axios'

// axios.defaults.baseURL = '/api'
if(process.env.NODE_ENV=='development'){
  // axios.defaults.baseURL = 'https://order.hwgwb.com/api'
  axios.defaults.baseURL = 'https://test.api.hwgwb.com/api'
}else{
  axios.defaults.baseURL = '/api'
}
export default axios
